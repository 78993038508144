<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div
          class="title"
        >福井エリアで人気のチケットです。福井の魅力的な観光スポットと人気施設が対象し、有効期間で対象施設の中からお好きな３つ施設を選んで楽しめます。また、電子チケットQRコードを提示すると簡単に入場ができます。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●各施設のご利用は1回のみです。同施設を2回ご利用できません。
          <br />●パスの使用期間は購入日から
          <span>90日</span>以内です。
          <br />EX：
          <span>7/1</span>ご購入の場合、
          <span>7/1~9/28</span>使用可能です。
          <br />●パスの有効期間：1つ目の施設をご入場から
          <span>7日間</span>以内です。
          <br />EX：
          <span>7/1</span>から利用開始の場合、有効期間は
          <span>7/7</span>までです。
          <br />●利用可能施設数：参画施設の中から、任意の<span>3施設</span>
          <br />●注意：同オーダーに複枚数をご購入の場合、中の一枚を利用すると、全て同じ
          <span>7日間</span>有効になります。
          <br />●パスの有効期間7日間は購入日から
          <span>90日間</span>以内に限ります。
          <br />EX：
          <span>7/1~9/28</span>使用可能のパスを
          <span>9/27</span>から利用開始された場合、
          有効期間は
          <span>9/27~9/28</span>です。
          <br />●未使用分を払い戻すことはできません。
          <br />●各施設の営業時間・定休日・利用に関する注意事項は下記リンク、
          または施設公式サイトでご確認ください。
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt />
            </div>
          </el-image>
        </div>

        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr />
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='引き換え場所' && !contentItem.noColor">
                      {{cItem}}
                      <br />
                    </span>
                    <a
                      :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]"
                      target="_blank"
                      :href="cItem"
                      v-show="msg.title==='公式サイト' && !contentItem.noColor"
                    >
                      {{cItem}}
                      <br />
                    </a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      headImg: require("@/assets/images/hffukui/ja/havefun_title-ja.jpg"),
      loadImg: require("@/assets/images/hfkansai/loading.png"),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hffukui/01_EN.jpg'),
          title: ['えちぜん鉄道全線1日乗り放題乗車券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '福井県福井市中央1丁目1-1 (えちぜん鉄道福井駅窓口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.echizen-tetudo.co.jp/'] },
                { cid: 2, text: ['利用可能路線図: '], noColor:true },
                { cid: 3, text: ['https://www.echizen-tetudo.co.jp/route/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['商品内容: えちぜん鉄道の三国芦原線・勝山永平寺線の両線が一日乗り放題です。','<br>※対象外：路線バス・福井鉄道'] },
                { cid: 2, text: ['必全線乗り放題乗車券に引き換えてからご利用ください。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 2,
          img: require('@/assets/images/hffukui/02_EN.jpg'),
          title: ["福井駅前の５つの店舗でご利用可能！そばをお得に食べよう♪そば食べ歩きクーポン券"],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '福井県福井市中央1-2-1 (ハピリン1階総合受付)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.happiring.com/event/detail.php?cd=1409'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['利用可能店舗: そば処 まる八、越前そば 見吉屋本店、あみだそば 福の井店、福井市観光物産福福館 福福茶屋、越前蕎麦倶楽部。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。','※臨時休業・満席等の事情でご利用いただけなかった場合があります。'] },
                { cid: 3, text: ['ハピリンモール1F総合カウンターにお越しいただき、QRコードをご提示ください。クーポン券をお渡しいたします。'] },
                { cid: 4, text: ['該当するお店へをお選びいただき入店。お食事を注文する際にご一緒に割引券をご提示ください。会計料金より、利用枚数分を割引させていただきます。','<br>※対象となるお食事は店舗によって異なります。','<br>※店舗によって使用できるクーポンの枚数が異なります。'] },
                { cid: 5, text: ['クーポン券1枚につき、300円分引換券が4つ付いています。'] },
                { cid: 6, text: ['クーポン券(実券)引き渡し日より半年間有効。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 3,
          img: require('@/assets/images/hffukui/03_EN.jpg'),
          title: ['JR福井駅前複合ビル「ハピリン」館内共通クーポン1000円分'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '福井県福井市中央1-2-1 (ハピリン1階総合受付)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.happiring.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['お会計時に各店舗でクーポン券を提示してください。（QRコードの提示ではご利用いただけません。必ずクーポン券に引き換えてから各店舗をご利用ください）'] },
                { cid: 3, text: ['本券は現金とのお引換え及びおつりのお渡しはできません。'] },
                { cid: 4, text: ['対象店舗以外でのご利用はできません。（館内のクーポン券対象外店舗：宝くじ、ファミリーマート、セーレンプラネット）'] },
                { cid: 5, text: ['クーポン券(実券)の有効期限は引き換え日から半年間です。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 4,
          img: require('@/assets/images/hffukui/04_EN.jpg'),
          title: ['丸岡城入場券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '福井県坂井市丸岡町霞町1-59 (丸岡城券売所)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.fuku-e.com/spot/detail_1018.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['商品内容: 「丸岡城」・「丸岡歴史民俗資料館」・「一筆啓上日本一短い手紙の館」の入場料'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['再入場はできません'] },
                ]
            }
          ]
        },
        // 
        {
          id: 5,
          img: require('@/assets/images/hffukui/05_EN.jpg'),
          title: ['あわら温泉にある「美松」日帰り入浴（レンタルタオル付）'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '福井県あわら市舟津26-10 (1階フロント)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.mimatu.net/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['商品内容: 温泉入浴料、バスタオルレンタル料'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['入浴は1回のみ。太陽殿は男性、明月殿は女性の浴場となります。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 6,
          img: require('@/assets/images/hffukui/06_EN.jpg'),
          title: ['越前松島水族館入館チケット 1500円クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '福井県坂井市三国町崎74-2-3(改札窓口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.echizen-aquarium.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1500円クーポンをご堪能いただけます。(入館料のみ)','<br>※おつりのお渡しはできません。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['台風・大雪・災害等で臨時休館したり、閉館時間を早めたり、開館時間を遅らせる場合がありますので、ご了承ください。'] }
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hffukui/07_EN.jpg'),
          title: ['恐竜博物館入館チケット（常設展観覧券）'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '福井県勝山市村岡町寺尾51-11 (本館総合受付)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.dinosaur.pref.fukui.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 2, text: ['"展示替えや施設の点検のため臨時に休館することがあります。'] },
                { cid: 3, text: ['券面(入館チケット)に記載の日付内であれば再入館できます。'] },

                ]
            }
          ]
        },
        // 
      ]
    };
  },
  created () {
    // this.$router.push('/hfPage?id=HFFukui&l=jp')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-fukui-1-week-free-pass/jp', '_self')
  }
};
</script>
  <style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #fffba0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1a1311;
    font-weight: 700;
    margin-top: 0;
    .title {
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span {
        color: #fa6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }

    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color: #ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>